









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.add_category_dialog_user {
  .el-dialog {
    @media (min-width: 320px) and (max-width: 767px) {
      width: 90% !important;
    }
  }
}
.main_mobile_wrapper_users {
  .el-table .cell {
    word-break: break-word !important;
  }
  .el-table__body > tbody > tr {
    td {
      @media (min-width: 320px) and (max-width: 480px) {
        padding: 0 !important;
      }
      .cell {
        @media (min-width: 320px) and (max-width: 480px) {
          padding: 0 !important;
          width: fit-content;
          word-break: break-word;
          white-space: normal;
          font-size: 12px;
        }
      }
    }
  }
}
.main_users_wrapping_container {
  .main_big_screen_wrapper_users {
    .el-table thead tr th:first-child,
    .el-table tbody tr td:first-child {
      padding-left: 0;
      width: 20px;
      // background: #f00;
    }
    .el-table__body > tbody > tr {
      td {
        padding: 12px 0 !important;

        .cell {
          padding: 0 5px;
          // @media (min-width: 320px) and (max-width: 480px) {
          //   padding: 0 !important;
          //   width: fit-content;
          //   word-break: break-word;
          //   white-space: normal;
          //   font-size: 12px;
          // }
        }
      }
    }
  }
}

// Start the_main_container_for_print_all_pdf
.the_main_container_for_print_all_pdf {
  display: none;
  @media print {
    display: block !important;
  }
  .main_container {
    .main_auth_user_info {
      text-align: center;
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .n_name {
        font-size: 24px;
        font-weight: bold;
        text-transform: capitalize;
      }
      .n_email {
        font-size: 22px;
      }
      .classification_classification {
        padding: 0 36px;
        min-width: 180px;
        border-radius: 12px;
        background-color: #00563f;
        height: 45px;
        display: flex;
        align-items: center;
        width: fit-content;
        color: #fff;
        margin: 10px auto;
      }
      // Below Score Cards
    }
    .the_patterns_with_new_theme {
      position: relative;
      .new_theme_pattern_content {
        // display: none;
        display: block;
        @media print {
          display: block !important;
        }
        padding: 0 10px;
        .main_content_container {
          border-left: 2px solid;
          border-right: 2px solid;
          border-bottom: 2px solid;
          text-align: center;
          padding: 10px 5px;
          &.with_sequence {
            border-color: #39c;
          }
          &.with_precision {
            border-color: #3c3;
          }
          &.with_technical_reasoning {
            border-color: #f93;
          }
          &.with_confluence {
            border-color: #c36;
          }
          .ti {
            // color: #555;
            font-size: 15px;
          }
          .te {
            color: #777;
            font-size: 13px;
          }
        }
      }
    }
    .wrapper_for_use_first {
      display: block;
      // display: none;
      @media print {
        display: block !important;
      }
      .main_co {
        background-color: #eee;
        display: flex;
        justify-content: center;
        gap: 10px;
        color: #7d7979;
        padding: 15px;
        .a_one {
          &.with_title {
            color: #555;
          }
        }
        .a_two {
          border-left: 3px solid #777;
          border-right: 3px solid #777;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
    .main_collapse_title_wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      padding: 20px 10px;
      .every_collapse_title {
        @media (min-width: 320px) and (max-width: 480px) {
          font-size: 18px;
        }
        @media (min-width: 320px) and (max-width: 350px) {
          font-size: 11px;
        }
      }
      .every_collapse_button {
        color: #fff;
        width: 50px;
        height: 50px;
        @media (min-width: 320px) and (max-width: 480px) {
          width: 36px;
          height: 36px;
        }
      }
      &.is_rtl {
        flex-direction: row-reverse;
        padding-right: 10px;
      }
    }

    .set_bg_white_for_share {
      background: #fff;
    }
    .alert_for_collapse {
      font-size: 1.2rem;
      @media (min-width: 320px) and (max-width: 767px) {
        font-size: 1rem;
      }
      /* Define custom class for page break */
      .print-page-break {
        page-break-inside: avoid;
      }
      .print-page-break_2 {
        page-break-inside: avoid;
      }
    }
    .el-collapse {
      background: #fff;
    }
    .wrapper_check_speech {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .icons_style {
      // background-color: #eee;
      // box-shadow: 0 0 6px 0 rgba(#000, 0.2), 0 0 5px -2px rgba(#000, 0.1);
      font-size: 14px;
      width: 30px;
      height: 30px;
      // box-shadow: $simple-shadow;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      cursor: pointer;
      color: #fff;
      transition: 0.3s;
      &.speech {
        &:hover {
          background-color: #11bfe3;
          color: #fff;
        }
      }
      &.delete {
        &:hover {
          background-color: #fb404b;
          color: #fff;
        }
      }
    }
  }
  .handler_strategy_style {
    font-size: 1.2rem;
    border-radius: 4px;
    color: #ffffff;
    padding: 10px 15px;
    line-height: 1.7;

    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 1rem;
    }
  }
  .letter {
    font-size: 40px;
  }
  .span_attr_type {
    font-size: 20px;
  }
}
@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /*Firefox*/
  }
  .hide_me_printable {
    display: none !important;
  }
  .looping_tasks_here {
    page-break-inside: avoid;
  }
  .print-page-break {
    page-break-after: always;
  }
  .print-page-break_2 {
    page-break-after: always;
  }
}
